import {useState} from 'react';
import '../App.css';
import './Components.css'

function Compliant(props) {

    const [sn,setSn] = useState(1)
    const snHandler = (e) =>{setSn(Number(e.target.value))}
  
    const [pn,setPn] = useState(1)
    const pnHandler = (e) =>{setPn(Number(e.target.value))}
  
    const [dn,setDn] = useState(1)
    const dnHandler = (e) =>{setDn(Number(e.target.value))}
  
    const rpn = sn*pn*dn
  
    const [compliant,setCompliant] = useState('ecss')
    const compliantHandler = (choice) => {
        setCompliant(choice)
        if (choice === "ecss") {setMaxNumber(4)}
    }
    
    const [max,setMax] = useState(1)
    const maxHandler = (e) =>{setMax(Number(e.target.value))}

    const [maxNumber,setMaxNumber] = useState(4)
    const maxNumberHandler = (e) => {
        setMaxNumber(Number(e.target.value))
    }

    const [custom,setCustom] = useState("")
    const [customRules, setCustomRules] = useState("")

    const customMaker = customRules.replaceAll(";"," || ").replaceAll(">=","aa").replaceAll("<=","bb").replaceAll("=","===").replaceAll("aa",">=").replaceAll('bb',"<=")

    let criticality
    switch(compliant) {
      case 'ecss':
        if(props.lang === "eng"){
           criticality = (sn>=3 || pn===4 ||dn===4 || rpn>=12 )? "⚠ Critical" : "Non-Critical"
        } else {
           criticality = (sn>=3 || pn===4 ||dn===4 || rpn>=12 )? "⚠ 危険あり" : "危険なし"
        }        
      break;
      case "custom":
        if(props.lang === "eng"){
            criticality = (eval(customMaker)) ? "⚠ Critical" : "Non-Critical";
        } else {
            criticality = (eval(customMaker)) ? "⚠ 危険あり" : "危険なし"
        }
        break;
      default:
        criticality = "";
    }
  
    let maxN
    switch(compliant) {
      case 'ecss':
      maxN = 4;
      break;
      case "custom":
        maxN = {max}
        break;
      default:
        maxN = 10;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setCustomRules(custom)
      }

return (
    <div className="compliant_wrapper">
        <div className="compliant_box">
        {props.lang === "eng"? "As a default compliance standard FMEAsy uses ECSS-Q-ST-30-02C(6March2009)(ECSS) standards. But you will have options to customize the app to meet conditions you specify. The application is built on the basis of Severity Number (SN), Probability Number (PN), Detection Number (DN), Risk Priority Number (RPN) etc. Bellow, you can try a simple ECSS criticality test or create your custom conditions." : "FMEAsyのデフォルト標準はECSS-Q-ST-30-02C(6March2009)(ECSS)ですが、カスタマイズした条件を設定することが可能になります。FMEAsyは影響度(SN), 頻度(PN), 検知度(DN)、 危険優先指数(RPN)のシステムに基づいて制作されています。こちらの簡易アプリでECSSテストもしくあなたの条件にカスタマイズしたテストも試してみてください。" }
        </div>
            <div className="compliant_box">
                <div className="compliant_selection">
                        <button onClick={() => compliantHandler('ecss') } style ={{backgroundColor:compliant ==="ecss"? "rgb(0, 22, 83)" : ""}}>ECSS</button>
                        <span>{`sn>=3; pn=4; dn=4; rpn>=12`}</span>

                        <button onClick={() => compliantHandler('custom')} style ={{backgroundColor:compliant ==="custom"? "rgb(0, 22, 83)" : ""}}>{props.lang === "eng"? "Custom" : "カスタム" }</button>
                </div>

                <div className="compliant_numbers">    
                    <div className="rpn_crit_box">
                    <div>
                        <label className="risk_modal_label">{props.lang === "eng"? "SN" : "影響度" }</label>
                        <input  type="number" min="1" max={maxNumber} value={sn} onInput={(e)=>{snHandler(e)}} className="risk_modal_number SN" />
                    </div>
                    <div>
                        <label className="risk_modal_label">{props.lang === "eng"? "PN" : "頻度" }</label>
                        <input type="number" min="1" max={maxNumber} value={pn} onInput={(e)=>{pnHandler(e)}} className="risk_modal_number PN"/>  
                    </div>
                    <div>
                        <label className="risk_modal_label">{props.lang === "eng"? "DN" : "検知度" }</label>
                        <input type="number" min="1" max={maxNumber} value={dn} onInput={(e)=>{dnHandler(e)}} className="risk_modal_number DN" />  
                    </div>
                    </div>
                    <div className="rpn_crit_box">
                        <div>
                            <label className="rpn_label">{props.lang === "eng"? "RPN" : "危険優先指数" }</label>
                            <div className="rpn" style={{backgroundColor:  criticality==="Non-Critical" || criticality==="危険なし" ? 'rgb(87, 72, 153)' : 'red'}}>{rpn}</div>
                        </div>
                        <div>
                            <label className="criticality_label">{props.lang === "eng"? "Criticality" : "危険度" }</label>
                            <div className="rpn_criticality" style={{color: criticality==="Non-Critical" || criticality==="危険なし" ? "":"red"}}>{criticality}</div>            
                        </div>
                    </div>
                </div>
            </div>
        {compliant === "custom"? 
        
        <div className="compliant_box compliant_options">
            <div className="compliant_custom_wrapper">
                <div className="compliant_custom">
                    <div className="custom_max">
                        <label className="custom_label">{props.lang === "eng"? "Set max Number" : "最大値" }</label>
                        <input  type="number" min="1"  value={maxNumber} onInput={(e)=>{maxNumberHandler(e)}} className="risk_modal_number" />
                    </div>
                    <form onSubmit={handleSubmit} className="custom_form">
                        <div className="custom_formula_box">
                            <label className="custom_label">{props.lang === "eng"? "Custom formula" : "カスタム臨界の式" }</label>
                            <textarea  
                                type="text" 
                                value={custom}
                                onChange={(e) => setCustom(e.target.value)}
                                className="custom_formula"
                                placeholder={props.lang === "eng"? "Write conditions and separate by semicolon (i.e. sn>=5;sn*pn>=20)" : "条件を入力して、「；」で分かれてください。(例えば sn>=5;sn*pn>=20)" } 
                                />
                        </div>
                        <input type="submit" className="custom_submit" value={props.lang === "eng"? "Submit" : "送信" }/>
                    </form>
                </div>
            </div>
        </div>  : "" }    
    </div>
)

}

export default Compliant