import {useState} from 'react';
import '../App.css';
import Compliant from './Compliant'
import Easyuse from './Easyuse'
import Easycol from './Easycol'

function Features(props) {

    const [featureSelected,setFeatureSelected]=useState("feature1")
    const featureSelectedHandler = (fe) => {
        setFeatureSelected(fe)
    }

    const feature = {
        feature1: <Easyuse lang={props.lang}/>,
        feature2: <Easycol lang={props.lang}/>,
        feature3: <Compliant lang={props.lang}/>,
    }

    return (
        <div className="features_app_wrapper">
            <div className="app_buttons">
                <button className={featureSelected === "feature1" ? "app_button app_button_selected" : "app_button"} onClick={()=>featureSelectedHandler("feature1")}>
                    <div>{props.lang === "eng" ? "Easy to use" : "使いやすい"}</div>
                </button>
                <button className={featureSelected === "feature2" ? "app_button app_button_selected" : "app_button"} onClick={()=>featureSelectedHandler("feature2")}>
                    <div>{props.lang === "eng" ? "Easy to collaborate" : "連携しやすい"}</div>
                </button>
                <button className={featureSelected === "feature3" ? "app_button app_button_selected" : "app_button"} onClick={()=>featureSelectedHandler("feature3")}>
                    <div>{props.lang === "eng" ? "Easy to customize" : "カスタマイズしやすい"}</div>
                </button>
            </div>
            <div className="app_content">
                {feature[featureSelected]}
            </div>
          
        </div>

    )
}

export default Features