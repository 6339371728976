import {useState} from 'react';
import '../App.css';
import './Components.css';
import img1 from '../img/FMEAsy0800_1.png';
import img2 from '../img/FMEAsy0800_2.png';
import img3 from '../img/FMEAsy0800_3.png';
import img4 from '../img/FMEAsy0800_4.png';
import img5 from '../img/FMEAsy0800_5.png';
import img6 from '../img/FMEAsy0800_6.png';
import img7 from '../img/FMEAsy0800_7.png';

function Easyuse(props) {

    const [modal,setModal] = useState(false)
    const modalHandler = () => {
        setModal(!modal)
    }

    const [featureBtn,setFeatureBtn] = useState (false)

    const gallery = [
        [img1,
            "FMEAsy procedure screen is where the most the job is done.",
            "FMEAsy手順のほとんどは、こちらの画面でされています。"],
        [img2,
            "You can register project branches, risk, provisions and even take pictures here.",
            "こちらで、部品部分、リスク、対策が登録できますし、写真もとることができます。"],
        [img3,
            "Some of of the features can be updated simply by drag and drop feature. (8.0.0.2)",
            "ある特徴はアップデートなど簡単にドラッグアンドドロップでできます。(8.0.0.2)"],
        [img4,
            "All data can be displayed in comprehensive tables. You can sort and filter all fields here.",
            "全てのデータは分かりやすい表で表示できます。データ並びもしくはフィルターはもちろんです。"],
        [img5,
            "These tables have also access to pictures you have taken during the process. (8.0.0.1)",
            "こちらの表で撮影された写真が表示できます。(8.0.0.1)"],
        [img6,
            "Or you can use more advanced picture features in FMEAsy gallery.",
            "もっとアドバンス写真の特徴はFMEAsyのギャレリーにあります。"],
        [img7,
            "Analyzing risks and provisions visually might come handy.",
            "ビジュアルの比較もしくは分析には役に立つに違いありません。"]
    ]

    const [picture, setPicture] = useState(0)
    const pictureHandler = (x) => {
        if ( x==="previous") {
            picture === 0 ? setPicture(gallery.length-1) : setPicture(picture-1) 
        }
        if (x ==="next") {
            picture === gallery.length-1? setPicture(0) : setPicture(picture+1)
        }
     }

    const [touchStart,setTouchStart]=useState(0)
    const touchStartHandler = (e) =>{
          setTouchStart(e.changedTouches[0].clientX)
    }

    const toucher = (e) => {
        if (touchStart-e.changedTouches[0].clientX < 100) {
            picture === 0 ? setPicture(gallery.length-1) : setPicture(picture-1) 
        }
        if (touchStart-e.changedTouches[0].clientX > 100) {
            picture === gallery.length-1? setPicture(0) : setPicture(picture+1)
        }
    }

    const [dragStart, setDragStart] = useState(0)
    const dragStartHandler = (e) => {
        setDragStart(e.clientX)
    }

    
    const dragger = (e) => {
        if (dragStart-e.clientX < 100) {
            picture === 0 ? setPicture(gallery.length-1) : setPicture(picture-1) 
        }
        if (dragStart-e.clientX > 100) {
            picture === gallery.length-1? setPicture(0) : setPicture(picture+1)
        }
    }


return (
    <div className="feature_box">
        <div className="feature_left">
            <div>
                <ul>
                    <h5>{props.lang ==="eng" ? "Functions" : "機能" }</h5>
                    <li className="implemented">{props.lang ==="eng" ? "Full FMEA/FMECA workflow in one place" : "故障モード影響解析の流れを1 か所にまとめる" }</li>
                    <li className="implemented">{props.lang ==="eng" ? "Implemented Camera" : "キメラ機能" }</li>
                    <li className="implemented">{props.lang ==="eng" ? "Interactive Gallery" : "インタラクティブギャラリー" }</li>
                    <li className="inprogress">{props.lang ==="eng" ? "File import and export" : "ファイルのエクスポートとインポート" }</li>
                    <li className="planned">{props.lang ==="eng" ? "Notes and drawing in photos" : "写真への注釈記入やマーキング" }</li>
                    <li className="planned">・・・</li>

                    <h5>{props.lang ==="eng" ? "Data" : "データ" }</h5>
                    <li className="implemented">{props.lang ==="eng" ? "Arranged tables" : "データ表" }</li>
                    <li className="inprogress">{props.lang ==="eng" ? "Search" : "検索機能" }</li>
                    <li className="inprogress">{props.lang ==="eng" ? "Analytics" : "分析" }</li>
                    <li className="planned">{props.lang ==="eng" ? "Search query editor" : "検索クエリのエディター" }</li>
                    <li className="planned">・・・</li>

                    <h5>{props.lang ==="eng" ? "Visuals" : "ビジュアル" }</h5>
                    <li className="implemented">{props.lang ==="eng" ? "Intuitive UI" : "直感的なUI設計" }</li>
                    <li className="implemented">{props.lang ==="eng" ? "Modern design" : "モダンなデザイン" }</li>
                    <li className="planned">・・・</li>

                </ul>
            </div>
            <div className="feature_help">
            <span className="implemented">{props.lang ==="eng" ? "Implemented" : "実施済み" }</span>
                <span className="inprogress">{props.lang ==="eng" ? "In progress" : "作成中" }</span>
                <span className="planned">{props.lang ==="eng" ? "Planned" : "企画中" }</span>
            </div>   
        </div>
        {modal? 
        <div 
        className="img_modal" 

         >   
          <img src={gallery[picture][0]} alt="easy to use" className="features_img"
                  onClick={modalHandler}
                  onTouchStart={touchStartHandler} 
                  onTouchEnd={toucher} 
                  onDragStart = {dragStartHandler} 
                  onDragEnd = {dragger}  
          />
          <div className="img_modal_text">
            <span className="img_modal_desc">{props.lang ==="eng" ? gallery[picture][1] : gallery[picture][2]}</span>
            <span className="img_modal_num">{picture+1}/7</span>   
          </div>
          <div className="img_modal_btn_box_right">
            <button className="img_modal_btn_right" onClick={()=>pictureHandler("next")}>▷</button>
          </div>
          <div className="img_modal_btn_box_left">
            <button className="img_modal_btn_left"  onClick={()=>pictureHandler("previous")}>◁</button>
          </div>
        </div>
        :""}
        <div className="feature_right">
            <div className="feature_img_box"
            onMouseEnter={()=>setFeatureBtn(true)}
            onMouseLeave={()=>setFeatureBtn(false)}>

                <img 
                    onClick={modalHandler} 
                    src={gallery[picture][0]}
                    alt="easy to use" 
                    className="features_img"
                    onTouchStart={touchStartHandler} 
                    onTouchEnd={toucher} 
                    onDragStart = {dragStartHandler} 
                    onDragEnd = {dragger}         
                    />
                <span className="feature_img_desc">{props.lang ==="eng" ? gallery[picture][1] : gallery[picture][2]}</span>
                <span className="feature_img_num">{picture+1}/7</span>
                {featureBtn? <button className="feature_img_btn_right" onClick={()=>pictureHandler("next")}>▷</button> :""}
                {featureBtn? <button className="feature_img_btn_left"  onClick={()=>pictureHandler("previous")}>◁</button> :""}
            </div>

        </div>

    </div>
)

}

export default Easyuse