import logo from './img/logo.png';
import {useRef, useState, useEffect} from 'react';
import './App.css';
import Features from './Components/Features'
import fmeasy_video from './img/fmeasy_video.mp4'


function App() {

  const headerRef = useRef(null)
  const aboutRef = useRef(null)
  const featuresRef = useRef(null)
  const progressRef = useRef(null)
  const authorRef = useRef(null)
  const scroll = (ref) => ref.current.scrollIntoView({behavior: 'smooth' })    
  

  const [scrollTop, setScrollTop] = useState(0);
  const [scrollTopI, setScrollTopI] = useState(0);
  const [screenHeight, setScreenHeight] = useState (window.innerHeight)
  
  useEffect(() => {
    const e = document.getElementById("hook");
    const handleScroll = event => {
      setScrollTop(window.scrollY);
          };
    const handleScrollI = event => {
      setScrollTopI(e.offsetTop);
    };
    const handleScreenHeight = event => {
      setScreenHeight(window.innerHeight);
    };


    window.addEventListener('scroll', () => {
      handleScroll(); 
      handleScreenHeight();
      handleScrollI();
    });
    window.addEventListener('resize', () => {
      handleScroll(); 
      handleScreenHeight();
      handleScrollI();
    });
    return () => {
    window.removeEventListener('scroll', () => {
      handleScroll(); 
      handleScreenHeight();
      handleScrollI();
    });
    };
  }, []);

  const style_navigation = {
    opacity: `${scrollTop - scrollTopI + 60}%`
  };

  const [lang,setLang]=useState("eng")
  const langHandler = (choice) => {
    setLang(choice)
  }


  return (
    <div className="app">
    <div className="space"/>
    <div className="overlay"/>
    <div className="overlay-header"/>
    <div className="page">
    <div className="top_menu" style={style_navigation}>
      <button className="minilogo" onClick={()=>{scroll(headerRef)}}><img  src={logo} alt="fmeasy-mini-logo"/></button>
    </div>
    <div className="lang_menu">
                <button className={lang==="eng" ? "lang_selected" : "lang_btn"} onClick={()=>langHandler("eng")}>English</button>
                <button className={lang==="jap" ? "lang_selected" : "lang_btn"}onClick={()=>langHandler("jap")}>日本語</button>
    </div>
    <div className="side_menu" style={style_navigation}>
      <button className="side_menu_button side_menu_about"  onClick={()=>{scroll(aboutRef)}}><div>{lang === "eng"? "About FMEAsy" : "FMEAsyについて"}</div></button>
      <button className="side_menu_button side_menu_features" onClick={()=>{scroll(featuresRef)}}><div>{lang === "eng"? "Features" : "特徴"}</div></button>
      <button className="side_menu_button side_menu_progress" onClick={()=>{scroll(progressRef)}}><div>{lang === "eng"? "Progress" : "進捗状況"}</div></button>
      <button className="side_menu_button side_menu_author" onClick={()=>{scroll(authorRef)}}><div>{lang === "eng"? "Contact" : "連絡先"}</div></button>
    </div>
        <header className="header" ref={headerRef}>      
              <div className="logo">
                <img src={logo} alt="fmeasy-logo"/>
                <span className="logo_text">{lang=== "eng" ? "Your modern tool for Failure Mode Effects and Analysis" : "故障モード影響解析を簡単に!　モダンなアプリで！"}</span>
                <span className="logo_version">{lang=== "eng" ? "Alfa version 0.8.0.2" : "Alfaバージョン0.8.0.2"}</span>
              </div>
              <div className="menu">
                <button className="menu_button menu_about" onClick={()=>{scroll(aboutRef)}}>{lang === "eng"? "About FMEAsy" : "FMEAsyについて"}</button>
                <button className="menu_button menu_features" onClick={()=>{scroll(featuresRef)}}>{lang === "eng"? "Features" : "特徴"}</button>
                <button className="menu_button menu_progress" onClick={()=>{scroll(progressRef)}}>{lang === "eng"? "Progress" : "進捗状況"}</button>
                <button className="menu_button menu_author" onClick={()=>{scroll(authorRef)}}>{lang === "eng"? "Contact" : "連絡先"}</button>
              </div>  
        </header>   
        <section id="hook" className="about" ref={aboutRef}>
            <div className="about_left">
              <h1 className="about_heading">{lang === "eng"? "ABOUT" : "FMEAsyについて"}</h1>
              <div className="about_text">
                <p>{lang === "eng" ? "FMEAsy is a project with a goal to provide modern application for FMEA and FMECA administration and processes. It takes European Space industry ECSS standarts as basis, but it will be customizable for various systems and approches. FMEAsy is surely going to be appreciated by technology and space startups all around the word. Check the video with the most recent visuals and progress." : "FMEAsyは、FMEA/FMECA（故障モード影響解析）の手順の流れを簡素化するためのモダンアプリのプロジェクトです。欧州宇宙標準協会のECSS標準に準拠にしていますが、カスタマイズ可能なので、様々な標準に設定できるようになります。FMEAsyは、きっと世界中のテクノロジーや宇宙関連スタートアップ企業のお役に立てるでしょう。FMEAsyの最新のビジュアルと進捗にご興味があれば、こちらのビデオをご覧ください。"}
                </p>                
                <p>{lang === "eng" ? "Built with Electron.js, React.js, MySQL and pure enthusiasm.":"このアプリはElectron.js・React.js・MySQL・情熱で制作しています。"}
                </p>  
              </div>  
            </div>          
            <div className="about_right">
              <video width="100%" height="100%" controls autoPlay="" preload="metadata">
                <source src={fmeasy_video} type="video/mp4"/>
              </video>
            </div>
        </section>
        <section className="features" ref={featuresRef} >
            <div className="overlay_features"/>
            <h1>{lang === "eng"? "FEATURES" : "特徴" }<br/><span className="logo_ver">{lang=== "eng" ? "Alfa version 0.8.0.2" : "Alfaバージョン0.8.0.2"}</span></h1>
            <Features lang={lang}/>
        </section>
        <section className="progress" ref={progressRef} >
            <div className="overlay_progress"/>
            <h1>{lang === "eng"? "PROGRESS" : "進捗状況"}</h1>
            <div className="progress_bar"><div></div></div>
            <div className="progress_wrapper">
                <ul> 
                    <li className="implemented">{lang === "eng"? "Concept" : "コンセプト"}</li>
                    <li className="implemented">{lang === "eng"? "Design" : "デザイン"}</li>
                    <li className="implemented">{lang === "eng"? `Basic fuctionalities & connectivity` : "基本機能と接続"}</li>
                    <li className="implemented">{lang === "eng"? "FMEA/FMECA Full Process" : "FMEA/FMECAの流れ"} </li>
                    <li className="implemented">{lang === "eng"? "Camera & Gallery feature" : "カメラとギャラリーの特徴"}</li>
                  <h5 className="implemented">{lang === "eng"? "Alfa VERSION 0.8.0.0" : "Alfaバージョン0.8.0.0"}<span className="progress_date">{lang === "eng"? " (3/2023)" : " (3月23年)"}</span></h5>
                    <li className="inprogress">{lang === "eng"? "Testing" : "テスティング"}</li>
                    <li className="inprogress">{lang === "eng"? "File handling" : "ファイル処理"}</li>
                    <li className="inprogress">{lang === "eng"? "Cloud database" : "クラウドデータベース"}</li>
                    <li className="inprogress">{lang === "eng"? "Customize options" : "カスタマイズ可"}</li>
                  <h5 className="inprogress">{lang === "eng"? "DEMO VERSION" : "デモ バージョン"}<span className="progress_date">{lang === "eng"? " (8/2023)" : " (8月23年)"}</span></h5>
                    <li className="planned">{lang === "eng"? "Bulk operations" : "一括処理"}</li>
                    <li className="planned">{lang === "eng"? "Advanced search" : "高度な検索機能"}</li>
                    <li className="planned">{lang === "eng"? "MS Azure, AWS etc. connectivity" : "MSAzure/AWS接続"}</li>
                    <li className="inprogress">{lang === "eng"? "Optimization" : "最適化"}</li>
                  <h5 className="planned">{lang === "eng"? "VERSION 1.0.0.0" : "バージョン1.0.0.0"}<span className="progress_date">{lang === "eng"? " (?)" : " (?月?年)"}</span></h5>
                  <li className="planned">・・・</li>
                </ul>
            </div>

        </section>
        <section className="author" ref={authorRef} >
            <h1>{lang === "eng"? "CONTACT" : "連絡先" }</h1>
            <div className="author_text">
            {lang === "eng"? <span>My name is Aleš Bachtík, I am the creator of the app and this site. If you are interested in FMEAsy or possible cooperation, feel free to contact me via email <a href="mailto:ales.bachtik7@gmail.com">ales.bachtik7@gmail.com</a>. You can also check my portfolio or my linkedIn to find out more about my other work and projects. </span> 
            : <span>私は　Bachtík Aleš（バフティーク・アレシュ）と申します。FMEAsyのアプリとこちらのウェブサイトの制作者です。FMEAsyアプリもしくは協力にご興味がある方は、こちら <a href="mailto:ales.bachtik7@gmail.com">ales.bachtik7@gmail.com</a>にご連絡ください。私のポートフォリオウェブサイトもしくはlinkedInもご覧ください。</span> } 
              <div className="author_links">
                <a href="https://www.ab-miniportfolio.com/" target="_blank" rel="noreferrer">
                  <div className="portfolio"> {lang === "eng"? "PORTFOLIO" : "ポートフォリオ" }</div>
                </a>
                <a href="https://www.linkedin.com/in/ale%C5%A1-bacht%C3%ADk-451920172/" target="_blank" rel="noreferrer">
                  <div className="linkedin"/>
                </a>                 
              </div>
            </div>  
        </section>
      </div>
    </div>
  );
}

export default App;