import {useState} from 'react';
import '../App.css';
import './Components.css'
import imgcoop from '../img/FMEAsy_MySQL.png';


function Easycol(props) {

    const [modal,setModal] = useState(false)
    const modalHandler = () => {
        setModal(!modal)
    }

return (
<div className="feature_box">
        <div className="feature_left">
            <div>
                <ul>
                    <h5>{props.lang ==="eng" ? "Database" : "データベース" }</h5>
                    <li className="implemented">{props.lang ==="eng" ? "MySQL database" : "MySQLデータベース" }</li>
                    <li className="planned">{props.lang ==="eng" ? "Automatic custom database instalation cloud/on-premises" : "自動的なインスタレーション（クラウド/オンプレミス)"}</li>
                   
                    <li className="planned">・・・</li>

                    <h5>{props.lang ==="eng" ? "Connectivity" : "接続" }</h5>
                    <li className="implemented">{props.lang ==="eng" ? "Offline mode" : "オフラインモード" }</li>
                    <li className="planned">{props.lang ==="eng" ? "Connect to Azure AD or similar services" : "AzureADなどの接続" }</li>
                    <li className="planned">・・・</li>

                    <h5>{props.lang ==="eng" ? "Flows" : "自動処理の流れ" }</h5>
                    <li className="planned">{props.lang ==="eng" ? "Automatic reminders" : "自動通知" }</li>
                    <li className="planned">{props.lang ==="eng" ? "Approvals" : "承認" }</li>
                    <li className="planned">・・・</li>

                </ul>
            </div>
            <div className="feature_help">
            <span className="implemented">{props.lang ==="eng" ? "Implemented" : "実施済み" }</span>
                <span className="inprogress">{props.lang ==="eng" ? "In progress" : "作成中" }</span>
                <span className="planned">{props.lang ==="eng" ? "Planned" : "企画中" }</span>
            </div>   
        </div>
        {modal? 
        <div className="img_modal" onClick={modalHandler}>   
          <img src={require('../img/FMEAsy_MySQL.png')} alt="easy to use" className="features_img"/>   
        </div>
        :""}
        <div className="feature_right">
            <div className="feature_img_box">
                <img onClick={modalHandler} src={imgcoop} alt="easy to use" className="features_img"/>
                <span className="feature_img_desc">{props.lang ==="eng" ? "FMEAsy uses MySQL database to store data." : "FMEAsyにはMySQLデータベースをデータストアーで使用されています"}</span>
            </div>
        </div>


    </div>
)

}

export default Easycol